import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Helmet from "react-helmet";
import { MarkdownLayout } from '../../partials/MarkdownLayout';
import { Colors, Fonts } from "../../materials";
import { ImageStaticFluid, Label, LinkGatsby, Heading, Container, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, TextBold } from "../../elements";
import { SnippetNavigationLandmarksAriaLabel } from '../../partials/SnippetNavigationLandmarksAriaLabel';
import { SnippetBreadcrumbs } from '../../partials/SnippetBreadcrumbs';
import { SnippetBreadcrumbsMarkup } from '../../partials/SnippetBreadcrumbsMarkup';
import { SnippetNavigationLandmarksAriaLabelledby } from '../../partials/SnippetNavigationLandmarksAriaLabelledby';
export const _frontmatter = {
  "seoTitle": "Accessible Breadcrumbs - example and best practices | Aditus",
  "seoURL": "https://www.aditus.io/patterns/breadcrumbs",
  "seoImage": "https://www.aditus.io/social/patterns/breadcrumbs.png",
  "seoImageAlt": "Text reading 'accessible breadcrumbs' with the Aditus logo next to it",
  "title": "Accessible Breadcrumbs",
  "description": "Learn how to create accessible breadcrumbs with practical examples and code samples.",
  "lead": ["When a website has a lot of pages, breadcrumbs can help a user find their current location within the overal hierarchy. This page shows how you can make breadcrumbs accessible to all users.\n"],
  "toc": {
    "nodes": [{
      "selfId": "what-are-breadcrumbs-and-when-to-use-them",
      "selfText": "What are they and when to use"
    }, {
      "selfId": "expectations",
      "selfText": "Expectations",
      "nodes": [{
        "selfId": "keyboard-support",
        "selfText": "Keyboard Support"
      }, {
        "selfId": "aria-roles-states-properties",
        "selfText": "ARIA"
      }]
    }, {
      "selfId": "technical-considerationg",
      "selfText": "Considerations",
      "nodes": [{
        "selfId": "1-using-the-nav-landmark",
        "selfText": "<code>&lt;nav&gt;</code> landmark"
      }, {
        "selfId": "2-use-ol-to-denote-hiearchy",
        "selfText": "<code>&lt;ol&gt;</code> element"
      }, {
        "selfId": "3-add-aria-current-to-the-current-page-link",
        "selfText": "<code>aria-current</code>"
      }, {
        "selfId": "4-optional-make-separators-decorative",
        "selfText": "decorative separators"
      }]
    }, {
      "selfId": "examples",
      "selfText": "Examples",
      "nodes": [{
        "selfId": "example-1-separators-in-css",
        "selfText": "Separators in CSS"
      }, {
        "selfId": "example-2-separators-in-html",
        "selfText": "Separators in HTML"
      }]
    }, {
      "selfId": "related-wcag-success-criterion",
      "selfText": "Related SC"
    }, {
      "selfId": "seo",
      "selfText": "SEO"
    }]
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <MarkdownLayout {...props} renderAfter={() => <>
      <nav aria-labelledby='related-topics'>
      <Box mt={6} />
      <Box bg={Colors.oc.gray[0]} py={8}>
        <Container maxWidth='l'>
          <Heading id='related-topics'>Related Topics</Heading>
          <Box mt={6} />
          <List display='flex' flexWrap='wrap' m={-4}>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/aria/aria-label/' css={{
              display: 'block',
              textDecoration: 'none',
              textAlign: 'left',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[1],
                border: `5px solid ${Colors.oc.blue[9]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>ARIA</Label>
                    <Text as='code' fontSize={5} css={{
                    fontFamily: Fonts.mono.family,
                    fontWeight: Fonts.mono.weight,
                    textDecoration: 'underline'
                  }}>
                      aria-label
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/aria/aria-current/' css={{
              display: 'block',
              textDecoration: 'none',
              textAlign: 'left',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[1],
                border: `5px solid ${Colors.oc.blue[9]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>ARIA</Label>
                    <Text as='code' fontSize={5} css={{
                    fontFamily: Fonts.mono.family,
                    fontWeight: Fonts.mono.weight,
                    textDecoration: 'underline'
                  }}>
                      aria-current
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/multiple-navigation-landmarks/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Multiple <code>{`<nav>`}</code>
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
          </List>
        </Container>
      </Box> 
    </nav>
    </>} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Box mt={8} mdxType="Box" />
    <h2>{`What are breadcrumbs and when to use them`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Breadcrumbs is an UX pattern that show where the current page is located
in the overall website's hierarchy. It is a type of secondary navigation,
that allows users to find their way back to the homepage.`}</p>
    <Box mt={6} mdxType="Box" />
    <p>{`You can use it you have several levels of navigation and want to make the
parent pages available as navigation.`}</p>
    <Box mt={8} mdxType="Box" />
    <Box mdxType="Box">
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/patterns/breadcrumbs-govuk.png' alt='Screenshot of gov.uk' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={2} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">Breadcrumbs on <LinkAnchor href='https://www.gov.uk' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Gov.uk</LinkAnchor>.</Text>
  <Box mt={6} mdxType="Box" />
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/patterns/breadcrumbs-amazoncom.png' alt='Screenshot of amazon.com' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={2} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">Breadcrumbs on <LinkAnchor href='https://www.amazon.com' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Amazon</LinkAnchor>.</Text>
  <Box mt={6} mdxType="Box" />
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/patterns/breadcrumbs-sbb.png' alt='Screenshot of sbb.ch' mdxType="ImageStaticFluid" />
  </Box>
  
  <Box mt={2} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">Breadcrumbs on <LinkAnchor href='https://www.sbb.ch' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">SBB</LinkAnchor>.</Text>
    </Box>
    <Box mt={8} mdxType="Box" />
    <h2>{`Expectations`}</h2>
    <h3>{`Keyboard Support`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`No keyboard interaction needed.`}</p>
    <h3>{`ARIA Roles, States and Properties`}</h3>
    <Box mt={4} mdxType="Box" />
    <table aria-labelledby='aria-roles-states-properties'>
  <thead>
    <tr>
      <th>Attribute</th>
      <th>Element</th>
      <th>Notes</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code>{`aria-label="breadcrumbs"`}</code>
      </td>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <code>{`<nav>`}</code>
      </td>
      <td>
        Describes the navigation to screen reader users (who navigate the page using landmarks)
      </td>
      <td>
        <LinkGatsby to='/aria/aria-label/' css={{
              whiteSpace: 'nowrap'
            }} mdxType="LinkGatsby">Learn more<span className='visually-hidden'> about <code>aria-label</code></span></LinkGatsby>
      </td>
    </tr>
    <tr>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code>{`aria-current="location"`}</code>
      </td>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <code>{`<a>`}</code>
      </td>
      <td>
        Indicates the last link as the current page.
      </td>
      <td>
        <LinkGatsby to='/aria/aria-current/' css={{
              whiteSpace: 'nowrap'
            }} mdxType="LinkGatsby">Learn more<span className='visually-hidden'> about <code>aria-current</code></span></LinkGatsby>
      </td>
    </tr>
  </tbody>
    </table>
    <Box mt={8} mdxType="Box" />
    <h2>{`Technical considerationg`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`As HTML does not have dedicated semantic elements for breadcrumbs,
developers need to add `}<LinkAnchor href='https://www.w3.org/TR/wai-aria/' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`ARIA attributes`}</LinkAnchor>{` to make it accessible for people
using screen readers`}</p>
    <h3>{`1. using the `}<inlineCode parentName="h3">{`nav`}</inlineCode>{` landmark`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`To make the breadcrumbs appear to users navigating the page using
landmarks, we can make use of the `}<inlineCode parentName="p">{`nav`}</inlineCode>{` element to wrap your markup.
As we most likely have more `}<inlineCode parentName="p">{`nav`}</inlineCode>{` elements on the page, it is a best practice
to `}<a parentName="p" {...{
        "href": "/patterns/multiple-navigation-landmarks/"
      }}>{`use `}<inlineCode parentName="a">{`aria-label`}</inlineCode>{` to label this landmark`}</a>{`.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`<nav aria-label="breadcrumbs">
  ...breadcrumbs go here
</nav>`} language='html' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h3>{`2. use `}<inlineCode parentName="h3">{`ol`}</inlineCode>{` to denote hiearchy`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`In order to make the set of links structured as a hierarchy, we
can use an ordered list (`}<inlineCode parentName="p">{`ol`}</inlineCode>{`) tag.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`<ol>
  <li>home</li>
  <li>parent</li>
  <li>current</li>
</ol>`} language='html' mdxType="Code" />
    </Box>
    <Box mt={6} mdxType="Box" />
    <h3>{`3. add `}<inlineCode parentName="h3">{`aria-current`}</inlineCode>{` to the current page link`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`To mark the last link of the list the current page,
we can make use of the `}<inlineCode parentName="p">{`aria-current='location'`}</inlineCode>{`.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`// ⛔ "Home, link"
<a
  href='/parent/current-page'
>
  Home
</a>
  
// ✅ "Home, current page link"
<a
  href='/parent/current-page'
  aria-current='location'
>
  Home
</a>`} language='html' mdxType="Code" />
    </Box>
    <h3>{`4. (optional) make separators decorative`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`When using markup for the breadcrumb's separator, it's important to make it
a decorative element, so that screen reader users don't announce it as text.`}</p>
    <Box mt={4} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`// ⛔ Separator as text content
<li>
  <a href='/'>Home</a>
  <span>►</span>
</li>
  
// ✅ Separator as decorative element
<li>
  <a href='/'>Home</a>
  <span aria-hidden="true">►</span>
</li>`} language='html' mdxType="Code" />
    </Box>
    <h2>{`Examples`}</h2>
    <h3>{`Example 1: Separators in CSS`}</h3>
    <Box mt={6} mdxType="Box" />
    <SnippetBreadcrumbs mdxType="SnippetBreadcrumbs" />
    <Box mt={8} mdxType="Box" />
    <h3>{`Example 2: Separators in HTML`}</h3>
    <Box mt={6} mdxType="Box" />
    <SnippetBreadcrumbsMarkup mdxType="SnippetBreadcrumbsMarkup" />
    <Box mt={8} mdxType="Box" />
    <h2>{`Related WCAG Success Criterion`}</h2>
    <Box mt={4} mdxType="Box" />
    <nav aria-labelledby='related-wcag-success-criterion'>
  <ul>
    <li>
      <LinkAnchor href='https://www.w3.org/WAI/WCAG21/Understanding/location.html' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        2.4.8: Location (AAA)
      </LinkAnchor>
    </li>  
    <li>
      <LinkAnchor href='https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        4.1.2: Name, Role, Value (Level A)
      </LinkAnchor>
    </li>  
  </ul>
    </nav>
    <Box mt={6} mdxType="Box" />
    <h2>{`SEO`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Some search engines also recognise this pattern and display results with this information, as shown below:`}</p>
    <Box mt={6} mdxType="Box" />
    <Box display='flex' flexWrap='wrap' m={-6} mdxType="Box">
  <Box p={6} flex='1 1 auto' mdxType="Box">
    <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
      <ImageStaticFluid path='articles/patterns/breadcrumbs-seo-without.png' alt='Screenshot of a result from google without breadcrumbs information' mdxType="ImageStaticFluid" />
    </Box>
    <Box mt={2} mdxType="Box" />
    <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">⛔ without JSON+LD</Text>
  </Box>
  <Box p={6} flex='1 1 auto' mdxType="Box">
    <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
      <ImageStaticFluid path='articles/patterns/breadcrumbs-seo-with.png' alt='Screenshot of a result from google with breadcrumbs information' mdxType="ImageStaticFluid" />
    </Box>
    <Box mt={2} mdxType="Box" />
    <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text">✅ with JSON+LD</Text>
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <p>{`We recommend to use `}<LinkAnchor href='https://json-ld.org/' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`JSON+LD`}</LinkAnchor>{`. We recommend
the reading of the `}<LinkAnchor href='https://schema.org/BreadcrumbList' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`BreadcrumbList Schema`}</LinkAnchor>{` format.
An example of markup using the Breadcrumb Pattern in JSON+LD is shown below:`}</p>
    <Box mt={6} mdxType="Box" />
    <Box display='flex' mdxType="Box">
  <Code codeString={`<script type="application/ld+json">
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://example.com/parent",
        "name": "Parent"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@id": "https://example.com/parent/current",
        "name": "Current"
      }
    }
  ]
}
</script>`} language='html' mdxType="Code" />
    </Box>
    <Box mt={10} mdxType="Box" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      